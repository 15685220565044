.gameFrame {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 20px;
}

#portrait {
    width: 30vw;
    height: 84vh;
    position: fixed;
    top: calc(100vh - 92vh);
    left: calc(100vw - 65vw);
}

#landscape {
    width: 80vw;
    height: 80vh;
    position: fixed;
    top: calc(100vh - 90vh);
    left: calc(100vw - 90vw);
}

@media only screen and (max-width: 1024px) {
    .gameFrame {
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0px;
    }

    #landscape {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        margin: 0;
        left: 0px;
    }

    #portrait {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        margin: 0;
        left: 0px;
    }
}

.backBtn {
    position: fixed;
    max-width: 6vw;
    z-index: 10;
}

.top {
    top: 20px;
    left: 20px;
}

.bottom {
    bottom: 3.6vh;
    left: 8vw;
}

.bottombubble {
    bottom: 16vh;
    left: 2vw;
}

.rotateDiv {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: rgba(46, 46, 46, 0.37);
    text-align: center;
    z-index: 9;
}

.rotateDiv img {
    height: 42vw;
    width: 42vw;
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
}

#hidden {
    display: none;
}
@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans&display=swap);
@font-face {
  font-family: 'Roboto Slab';
  src: url(/static/media/RobotoSlab-variable.0b2aeb1c.ttf) format("truetype-variations");
  font-weight: 1 999;
}

@font-face {
  font-family: 'Cooper Black';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/cooper-black.8eb03871.ttf); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
       url(/static/media/cooper-black.8eb03871.ttf) format('truetype'); /* Safari, Android, iOS */
}

@-webkit-keyframes out {
  0% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  10% {
    transform: translate(0px, 10%);
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(0px, -100%);
    opacity: 0;
    display: none;
  }
}

@keyframes out {
  0% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  10% {
    transform: translate(0px, 10%);
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(0px, -100%);
    opacity: 0;
    display: none;
  }
}

#root {
  max-width: 583px;
  margin: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 1024px) {
  #root {
    max-width: 1024px;
  }
}

body {
  background-color: lightseagreen;
  margin: 0;
  font-family: 'Roboto Slab';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.toast {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 70px;
  border-radius: 15px;
  background-color: #fff;
  color: lightgreen;
  font-size: 21px;
  text-align: center;
  z-index: 2000;
  display: none;
}

.play-reverse {
  display: block;
  -webkit-animation-name: out;
          animation-name: out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  animation-direction: reverse;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.play {
  display: block;
  -webkit-animation-name: out;
          animation-name: out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; 
  -webkit-animation-fill-mode: forwards; 
          animation-fill-mode: forwards;
}

.toast p {
  position: relative;
  top: 25%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.none {
  display: none;
}

.active {
  display: block;
}

.notify {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #7E40AF;
  color: white;
  width: 100vw;
  height: 16vh;
  z-index: 1500;
  text-align: center;
  padding: 2%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: 'Roboto Slab', serif;
}

.notify button {
  background-color: crimson;
  border: 0px;
  border-radius: 10px;
  height: 40%;
  color: white;
  width: 45%;
  font-family: 'Roboto Slab';
}

.sweet-loading {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.2);
}

.popup {
  height: auto;
  max-height: 80vh;
  width: 80vw;
  background-color: #38EBB5;
  position: fixed;
  z-index: 100;
  top: 10vh;
  left: calc(100vw - 90vw);
  border-radius: 50px;
  color: white;
  text-align: center;
  padding: 3%;
}

.popup h4 {
  font-size: 4vw;
}

.popup h5 {
  font-size: 3vw;
}

.popup::after {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}

@media only screen and (max-width: 1024px) {
  .popup {
    height: auto;
    max-height: 80vh;
    width: 80vw;
    background-color: #38EBB5;
    position: absolute;
    z-index: 100;
    top: 10vh;
    left: calc(100vw - 90vw);
    border-radius: 50px;
    color: white;
    text-align: center;
    padding: 10%;
  }
  
  .popup h4 {
    font-size: 6vw;
  }
  
  .popup h5 {
    font-size: 5vw;
  }
  
  .btnClose {
    transition: all 0.1s;
    position: absolute;
    top: 0px;
    right: 0px;
    max-width: 12%;
    padding: 2%;
    background-color: seashell;
    border-radius: 100%;
  }
}

.btnClose {
  transition: all 0.1s;
  position: absolute;
  top: 0px;
  right: 0px;
  max-width: 10%;
  padding: 2%;
  background-color: seashell;
  border-radius: 100%;
}

.btnClose:active {
  transition: all 0.1s;
  background-color: tomato;
}

.install {
  font-family: 'Roboto Slab', serif;
  color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 15vh;
  background-color: #58B1F0;
  border-bottom: 6px solid #D6E1EA;
  -webkit-filter: drop-shadow(3px 3px 3px #333);
          filter: drop-shadow(3px 3px 3px #333);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  padding: 10px;
  opacity: 0.98;
  -webkit-animation-name: enter;
          animation-name: enter;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.install button {
  font-family: 'Roboto Slab', serif;
  color: white;
  background-color: #F96A5B;
  margin-top: 5px;
  border: 0px;
  border-radius: 10px;
  height: 40%;
  width: 30%;
}

.install img {
  position: absolute;
  top: 10px;
  right: 10px;
  max-height: 15%;
}

.invalid-feedback {
    display: block;
}

.custom-toast {
    background-color: green !important;
    color: white !important;
}

.link-login-toast {
    float: right;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    border-color: #fff;
    border: 1px solid;
    padding: 1px 5px;
    margin: 0, 5px, 5px, 0;
}

body {
    display: block;
    padding: 0;
}

.body {
    max-width: 583px;
    margin: auto;
    background-image: url(/static/media/background_home.f7ed94ec.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat center;
    height: 100vh;
  }
  
  @media only screen and (max-width: 1024px) {
    .body {
      max-width: 1024px;
    }
  }

.verticalDrawer {
    position: relative;
    top: 11vh;
    left: 10%;
    margin-bottom: 2vh;
    padding: 5%;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 15px;

}

.verticalDrawer ol {
    list-style: none;
}

.verticalDrawer #game {
    width: 100%;
    margin-bottom: 5%;
    border: 6px solid #fff;
    border-radius: 15px;
}

#rank {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    font-family: 'Balsamiq Sans', cursive;
}

#rank th {
    border-bottom: 1px solid azure;
    padding: 5%;
    font-size: 1.5vh;
    text-transform: uppercase;
    color: azure;
}

#rank th, td:last-child {
    color: rgb(255, 254, 176);
}

tr:hover {
    background-color:#9e9e9e73;
    border-radius: 15px;
}

.fixedFooter {
    position: fixed;
    bottom: 0px;
    left: 3vw;
    margin-bottom: 2vh;
    padding: 5%;
    width: calc(100vw - 6vw);
    background-color: rgba(0,0,0,0.3);
    border-radius: 15px;
}

#yourRank {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    font-family: 'Balsamiq Sans', cursive;
}

#yourRank th, td {
    border-top: 1px solid azure;
    border-bottom: 1px solid azure;
    padding: 5%;
    font-size: 1.5vh;
    text-transform: uppercase;
    color: azure;
}

#yourRank th:last-child {
    color: rgb(255, 254, 176);
}

#gameDiv {
    position: relative;
}

#gameDiv:hover {
    opacity: 0.9;
}

#gameDiv #rank {
    position: absolute;
    top: 15px;
    right: 15px;
    max-width: 10%;
    -webkit-filter: drop-shadow(0px 0px 5px #5e4d26);
            filter: drop-shadow(0px 0px 5px #5e4d26);
}

iframe {
    border: 0px;
}
.gameFrame {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 20px;
}

#portrait {
    width: 30vw;
    height: 84vh;
    position: fixed;
    top: calc(100vh - 92vh);
    left: calc(100vw - 65vw);
}

#landscape {
    width: 80vw;
    height: 80vh;
    position: fixed;
    top: calc(100vh - 90vh);
    left: calc(100vw - 90vw);
}

@media only screen and (max-width: 1024px) {
    .gameFrame {
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0px;
    }

    #landscape {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        margin: 0;
        left: 0px;
    }

    #portrait {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        margin: 0;
        left: 0px;
    }
}

.backBtn {
    position: fixed;
    max-width: 6vw;
    z-index: 10;
}

.top {
    top: 20px;
    left: 20px;
}

.bottom {
    bottom: 3.6vh;
    left: 8vw;
}

.bottombubble {
    bottom: 16vh;
    left: 2vw;
}

.rotateDiv {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: rgba(46, 46, 46, 0.37);
    text-align: center;
    z-index: 9;
}

.rotateDiv img {
    height: 42vw;
    width: 42vw;
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
}

#hidden {
    display: none;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/RobotoSlab-variable.ttf') format("truetype-variations");
  font-weight: 1 999;
}

@font-face {
  font-family: 'Cooper Black';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/cooper-black.ttf'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
       url('fonts/cooper-black.ttf') format('truetype'); /* Safari, Android, iOS */
}

@keyframes out {
  0% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  10% {
    transform: translate(0px, 10%);
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(0px, -100%);
    opacity: 0;
    display: none;
  }
}

#root {
  max-width: 583px;
  margin: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 1024px) {
  #root {
    max-width: 1024px;
  }
}

body {
  background-color: lightseagreen;
  margin: 0;
  font-family: 'Roboto Slab';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.toast {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 70px;
  border-radius: 15px;
  background-color: #fff;
  color: lightgreen;
  font-size: 21px;
  text-align: center;
  z-index: 2000;
  display: none;
}

.play-reverse {
  display: block;
  animation-name: out;
  animation-duration: 1s;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}

.play {
  display: block;
  animation-name: out;
  animation-duration: 1s; 
  animation-fill-mode: forwards;
}

.toast p {
  position: relative;
  top: 25%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.none {
  display: none;
}

.active {
  display: block;
}

.notify {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #7E40AF;
  color: white;
  width: 100vw;
  height: 16vh;
  z-index: 1500;
  text-align: center;
  padding: 2%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: 'Roboto Slab', serif;
}

.notify button {
  background-color: crimson;
  border: 0px;
  border-radius: 10px;
  height: 40%;
  color: white;
  width: 45%;
  font-family: 'Roboto Slab';
}

.sweet-loading {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.2);
}

.popup {
  height: auto;
  max-height: 80vh;
  width: 80vw;
  background-color: #38EBB5;
  position: fixed;
  z-index: 100;
  top: 10vh;
  left: calc(100vw - 90vw);
  border-radius: 50px;
  color: white;
  text-align: center;
  padding: 3%;
}

.popup h4 {
  font-size: 4vw;
}

.popup h5 {
  font-size: 3vw;
}

.popup::after {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}

@media only screen and (max-width: 1024px) {
  .popup {
    height: auto;
    max-height: 80vh;
    width: 80vw;
    background-color: #38EBB5;
    position: absolute;
    z-index: 100;
    top: 10vh;
    left: calc(100vw - 90vw);
    border-radius: 50px;
    color: white;
    text-align: center;
    padding: 10%;
  }
  
  .popup h4 {
    font-size: 6vw;
  }
  
  .popup h5 {
    font-size: 5vw;
  }
  
  .btnClose {
    transition: all 0.1s;
    position: absolute;
    top: 0px;
    right: 0px;
    max-width: 12%;
    padding: 2%;
    background-color: seashell;
    border-radius: 100%;
  }
}

.btnClose {
  transition: all 0.1s;
  position: absolute;
  top: 0px;
  right: 0px;
  max-width: 10%;
  padding: 2%;
  background-color: seashell;
  border-radius: 100%;
}

.btnClose:active {
  transition: all 0.1s;
  background-color: tomato;
}

.install {
  font-family: 'Roboto Slab', serif;
  color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 15vh;
  background-color: #58B1F0;
  border-bottom: 6px solid #D6E1EA;
  filter: drop-shadow(3px 3px 3px #333);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: center;
  padding: 10px;
  opacity: 0.98;
  animation-name: enter;
  animation-duration: 1s;
}

.install button {
  font-family: 'Roboto Slab', serif;
  color: white;
  background-color: #F96A5B;
  margin-top: 5px;
  border: 0px;
  border-radius: 10px;
  height: 40%;
  width: 30%;
}

.install img {
  position: absolute;
  top: 10px;
  right: 10px;
  max-height: 15%;
}

.invalid-feedback {
    display: block;
}

.custom-toast {
    background-color: green !important;
    color: white !important;
}

.link-login-toast {
    float: right;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    border-color: #fff;
    border: 1px solid;
    padding: 1px 5px;
    margin: 0, 5px, 5px, 0;
}
